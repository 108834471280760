import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';

import BookingForm from '../../components/booking-form';
import Footer from '../../components/footer';
import Layout from '../../components/layout';
import NavSection from '../../components/nav-section';
import VicinityMap from '../../components/vicinity-map';

export class VisitPage extends React.Component {
  render() {
    const navMenus = get(this, 'props.data.allContentfulNavigation.edges');
    
    const footer = get(this, 'props.data.allContentfulFooter.edges')
    
    const bookingForm = get(this, 'props.data.allContentfulBookingForm.edges')[0].node;
    return (
      <Layout color="lightBrown">
        <NavSection navMenus={navMenus} navTheme="nav-white" />
        <Helmet title="Venues - Visit the Palacio" />
        <div className="container">
          <div className="gallery-header">
            <h1>Visit the Palacio</h1>
          </div>
        </div>

        <div className="container pb-2 clearfix map-image-container">
          <VicinityMap />
        </div>

        <BookingForm bookingForm={bookingForm}/>
        <Footer 
          footer={footer}
        />
      </Layout>
    );
  }
}

export default VisitPage;

export const pageQuery = graphql`
  query VisitPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulNavigation(sort: { fields: createdAt }) {
      edges {
        node {
          id
          slug
          pageTitle
          submenu
        }
      }
    }
    allContentfulBookingForm {
      edges {
        node {
          header
          firstNameInputPlaceholder
          lastNameInputPlaceholder
          contactNoInputPlaceholder
          emailInputPlaceholder
          bookingTypeInputPlaceholder {
            name
            value
          }
          dateInputPlaceholder
          timeInputPlaceholder
          venueInputPlaceholder {
            name
            value
            selected_value
          }
          messageInputPlaceholder
          newsletterInputPlaceholder
          submitInputPlaceholder
        }
      }
    }
    allContentfulFooter {
      edges {
        node {
          facebookLink
          footerAddress
          footerEmail
          footerHeadline
          footerImage {
            file {
              url
            }
          }
          footerLandline
          footerMobileNumber
          footerOpeningDates
          footerOpeningHours
          instagramLink
          section
        }
      }
    }
  }
`;
