import React from 'react';
import DatePicker from 'react-datepicker';

class BookingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      venueDate: '',
      venueTime: '',
    };
  }

  render() {
    const { venueDate, venueTime } = this.state;
    const bookingForm = this.props.bookingForm; 
    return (
      <div
        className="container mt-5 pt80 pb-5 mb-4 bordered-top venue-form"
        id="bookingForm"
      >
        <div className="row justify-content-between">
          <div className="col-lg-6">
            <h1>{bookingForm.header}</h1>
          </div>
          <div className="col-lg-5">
            <form
              className="default-form"
              action="https://gmail.us5.list-manage.com/subscribe/post?u=8888c103642d087afb1f42fef&amp;id=72e829b037&amp;SIGNUP=Venues%20Page"
              method="post"
            >
              <div className="row">
                <div className="col-md-6">
                  <input type="text" name="FNAME" placeholder={bookingForm.firstNameInputPlaceholder} />
                </div>
                <div className="col-md-6">
                  <input type="text" name="LNAME" placeholder={bookingForm.lastNameInputPlaceholder} />
                </div>
                <div className="col-md-6">
                  <input type="text" name="PHONE" placeholder={bookingForm.contactNoInputPlaceholder} />
                </div>
                <div className="col-md-6">
                  <input
                    type="email"
                    name="EMAIL"
                    placeholder={bookingForm.emailInputPlaceholder}
                  />
                </div>
                <div className="col-md-12">
                  <select name="BOOKING" required defaultValue={bookingForm.bookingTypeInputPlaceholder[0].value}>
                      {bookingForm.bookingTypeInputPlaceholder.map((option, i) => (
                         <option value={option.value} disabled={i == 0 ? true : false} key={i}>
                         {option.name}
                       </option>
                      ))}
                    </select>
                  <img src="/images/arrow.png" className="select-arrow" />
                </div>
                <div className="col-md-6">
                  <DatePicker
                    selected={venueDate}
                    onChange={date => this.setState({ venueDate: date })}
                    showPopperArrow={false}
                    placeholderText={bookingForm.dateInputPlaceholder}
                    name="SETDATE"
                  />
                  <img src="/images/arrow.png" className="select-arrow" />
                </div>
                <div className="col-md-6">
                  <DatePicker
                    selected={venueTime}
                    onChange={date => this.setState({ venueTime: date })}
                    showPopperArrow={false}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    placeholderText={bookingForm.timeInputPlaceholder}
                    name="SETTIME"
                  />
                  <img src="/images/arrow.png" className="select-arrow" />
                </div>
                <div className="col-md-12">
                  <select name="ROOMFLOOR" required defaultValue={bookingForm.venueInputPlaceholder[0].value}>
                      {bookingForm.venueInputPlaceholder.map((option, i) => (
                         <option value={option.value} disabled={i == 0 ? true : false} selected={this.props.floor == option.selected_value} key={i}>
                         {option.name}
                       </option>
                      ))}
                    </select>
                  <img src="/images/arrow.png" className="select-arrow" />
                </div>
                <div className="col-md-12">
                  <textarea
                    name="MESSAGE"
                    rows="3"
                    placeholder={bookingForm.messageInputPlaceholder}
                  ></textarea>
                </div>
                <div className="col-md-12">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-md-8 mt-2 mb-2">
                      <label className="checkbox-label">
                        <input type="checkbox" required /> {bookingForm.newsletterInputPlaceholder}
                      </label>
                    </div>
                    <div className="col-md-4 mt-3 mb-2">
                      <button className="btn w-100" type="submit">
                      {bookingForm.submitInputPlaceholder}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default BookingForm;
